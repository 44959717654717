import { config } from '@/config'
import type { ServerError } from '@/utils/redux/store/types'

import type { TokenService } from './TokenService'
import type { req as request, RequestOptions } from './utils/req'

type ClientOptions = {
  tokenService: TokenService
  req: typeof request
  setTokenValid: (d: boolean) => boolean
}

class Client {
  tokenService: TokenService
  setTokenValid: (d: boolean) => boolean
  req: typeof request

  constructor(options: ClientOptions) {
    const { tokenService, req, setTokenValid } = options

    this.tokenService = tokenService
    this.setTokenValid = setTokenValid
    this.req = req
  }

  setTokens({ token }: { token: string }): void {
    this.tokenService.token = token
  }

  signin({
    email,
    password,
  }: {
    email: string
    password: string
  }): Promise<boolean> {
    return this.requestAndStoreToken('/v1/auth/signin', {
      method: 'POST',
      body: {
        email,
        password,
      },
    })
  }

  isAuthed(): boolean {
    return !!this.tokenService.token
  }

  signup({
    name,
    email,
    password,
    invitationToken,
    invite,
  }: {
    name: string
    email: string
    password: string
    invitationToken: string
    invite: string
  }): Promise<boolean> {
    return this.requestAndStoreToken('/v1/auth/signup', {
      method: 'POST',
      body: { name, email, password, invitationToken, invite },
    })
  }

  logout({ redirect }: { redirect?: string } = {}): void {
    this.tokenService.clearToken()

    if (typeof window !== 'undefined') {
      if (redirect) {
        window.location.href = redirect
      } else {
        window.location.reload()
      }
    }
  }

  async request<T>(url: string, options?: RequestOptions): Promise<T> {
    const token = this.tokenService.token

    try {
      const response = await this.req<T>(token, url, options)
      return response
    } catch (error) {
      const err = error as ServerError
      if (error) {
        switch (err.code) {
          case 'TOKEN_EXPIRED': {
            await this.tokenService.retrieveToken()

            return this.request(url, options)
          }
          case 'INVALID_TOKEN': {
            this.setTokenValid(false)
            this.logout({ redirect: config.baseAppUrl })
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            return
          }
          default: {
            throw error
          }
        }
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      return
    }
  }

  requestWithoutToken<T = unknown>(
    url: string,
    options?: RequestOptions
  ): Promise<T> {
    return this.req<T>(null, url, options)
  }

  async requestAndStoreToken(
    url: string,
    options: RequestOptions
  ): Promise<boolean> {
    const {
      data: { token },
    } = await this.requestWithoutToken<{ data: { token: string } }>(
      url,
      options
    )

    this.setTokens({ token })
    this.setTokenValid(true)

    return true
  }

  checkToken(): boolean {
    if (!this.tokenService.token) {
      return this.setTokenValid(false)
    }

    return this.setTokenValid(true)
  }
}

export { Client }
