import { number, string } from 'prop-types'
import React, { PureComponent } from 'react'

import { Loading as LoadingIcon } from '../../../../ui-kit/atoms/SvgIcon/icons/Loading'

import styles from './Loader.css'

export class Loader extends PureComponent {
  static propTypes = {
    size: number,
    testId: string,
  }

  static defaultProps = {
    size: 24,
  }

  render() {
    const { size, testId } = this.props

    return (
      <div className={styles.loader} data-testid={testId}>
        <div className={styles.wrapper}>
          <LoadingIcon width={size} height={size} />
        </div>
      </div>
    )
  }
}
