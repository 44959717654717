import { ConnectedRouter } from 'connected-react-router'
import type { History } from 'history'
import React from 'react'
import { CookiesProvider } from 'react-cookie'
import { Provider } from 'react-redux'

import type { Store } from './utils/redux/store/types'

type Props = {
  history: History
  store: Store
  routes: React.ReactElement
}

class App extends React.Component<Props> {
  render(): React.ReactElement {
    const { store, routes, history } = this.props

    return (
      <Provider store={store}>
        <CookiesProvider>
          <ConnectedRouter history={history}>{routes}</ConnectedRouter>
        </CookiesProvider>
      </Provider>
    )
  }
}

export { App }
