import { config } from '@/config'

import { ConnectionError, ResponseError } from './errors'

export type RequestOptions = {
  method?: string
  headers?: HeadersInit
  body?: Record<string, unknown>
  signal?: AbortSignal | null
}

export async function req<T>(
  token: string | null,
  url: string,
  options: RequestOptions = {}
): Promise<T> {
  const headers: HeadersInit = {
    ...options.headers,
    'Content-Type': 'application/json',
    ...(token
      ? {
          Authorization: token,
        }
      : {}),
  }

  let response: Response = new Response()

  try {
    response = await fetch(`${config.apiUrl}${url}`, {
      ...options,
      credentials: 'include',
      headers,
      body: options.body && JSON.stringify(options.body),
    })
  } catch (error) {
    const err = error as { name: string }
    if (err.name === 'AbortError') {
      console.warn('Request aborted due to duplicate')
    } else {
      throw new ConnectionError()
    }
  }

  const parsedResponse = await (/application\/json/.test(
    response.headers.get('Content-Type') || ''
  )
    ? response.json()
    : response.blob())

  if (response.ok) {
    return parsedResponse
  }

  if (parsedResponse.error) {
    throw parsedResponse.error
  }

  throw new ResponseError({
    status: response.status,
    message: response.statusText,
  })
}
