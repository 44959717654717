import { createAction } from 'redux-act'
import { Cmd, loop } from 'redux-loop'

import type { Payload } from '@/utils/redux/store/types'

import { REDUCER_KEY } from './constants'
import type { Locale, LocaleState } from './types'

export const getLocaleMessages = createAction(
  `${REDUCER_KEY}/getLocaleMessages`
)
export const getLocaleMessagesSuccess = createAction<{
  result: { dictionary: Record<string, string> }
  locale: Locale
}>(`${REDUCER_KEY}/getLocaleMessagesSuccess`)
export const getLocaleMessagesFailure = createAction(
  `${REDUCER_KEY}/getLocaleMessagesFailure`
)

export const requestGetLocaleMessages = () => (
  locale: Locale
): Promise<{
  dictionary: Record<string, string>
}> => {
  let importPromise = null

  switch (locale) {
    default: {
      importPromise = import('./data/en-US')
      break
    }
  }

  return importPromise
}

export const handleGetLocaleMessages = (
  state: LocaleState,
  { payload: locale }: Payload<Locale>
): LocaleState =>
  (loop(
    {
      ...state,
      isLoading: true,
    },
    Cmd.run(requestGetLocaleMessages(), {
      args: [locale],
      successActionCreator: (result: { dictionary: Record<string, string> }) =>
        getLocaleMessagesSuccess({ result, locale }),
      failActionCreator: getLocaleMessagesFailure,
    })
  ) as unknown) as LocaleState

export const handleGetLocaleMessagesSuccess = (
  state: LocaleState,
  {
    payload,
  }: Payload<{
    result: {
      dictionary: Record<string, string>
    }
    locale: Locale
  }>
): LocaleState => ({
  ...state,
  isLoading: false,
  isLoaded: true,
  messages: payload.result.dictionary,
  locale: payload.locale,
})

export const handleGetLocaleMessagesFailure = (
  state: LocaleState
): LocaleState => ({
  ...state,
  isLoading: false,
})
