import 'whatwg-fetch'

import { Client } from './Client'
import { TokenService } from './TokenService'
import { req } from './utils/req'

const tokenService = new TokenService({
  req,
})

export const createApi = (setTokenValid: (d: boolean) => boolean): Client =>
  new Client({
    tokenService,
    req,
    setTokenValid,
  })
