import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { registerModule as mainModule } from '@/components/main'
import type { ModuleContext } from '@/utils/redux/types'

const getRoutes = (context: ModuleContext): React.ReactElement => {
  const modules = [
    mainModule,
    // ~~ ADD HERE ~~ Do not remove
  ]

  const routes = modules.map((module) => module(context)).filter(Boolean)
  return (
    <Switch>
      {routes.map((params) => (
        <Route key={params.path} {...params} />
      ))}
    </Switch>
  )
}

export { getRoutes }
