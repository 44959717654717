/* eslint-disable no-underscore-dangle */
const linkLength =
  process.env.REACT_APP_REPORT_LINK_MAX_LENGTH ??
  window.__env__.reportLinkMaxLength

export const config = {
  baseAppUrl: '/app',
  publicUrl: process.env.PUBLIC_URL || '',
  apiUrl: process.env.REACT_APP_API_URL ?? window.__env__.apiUrl,
  reportLinkMaxLength: linkLength ? Number(linkLength) : 2048,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN ?? window.__env__.sentryDsn,
  sentryEnvironment:
    process.env.REACT_APP_SENTRY_ENVIRONMENT ??
    window.__env__.sentryEnvironment,
  sentryRelease:
    process.env.REACT_APP_SENTRY_RELEASE ?? window.__env__.sentryRelease,
}
