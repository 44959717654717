import { createBrowserHistory } from 'history'
import moment from 'moment'
import React from 'react'
import { Cookies } from 'react-cookie'
import ReactDOM from 'react-dom'
import { composeWithDevTools } from 'redux-devtools-extension'

import { App } from './App'
import { config } from './config'
import { getRoutes as routes } from './routes'
import { configureStore, createWhen } from './utils/redux'

import 'react-dates/initialize'
import 'wicg-focus-ring'
import './ui-kit/styles/base.css'
import './ui-kit/styles/react-dates.css'

moment.locale('en')
moment.updateLocale('en', {
  weekdaysMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  week: { dow: 1 },
})

const history = createBrowserHistory({ basename: config.baseAppUrl })

const toggleDevTools = !process.env.DISABLE_REDUX_DEVTOOLS
  ? composeWithDevTools({
      shouldHotReload: false,
    })
  : undefined

export const store = configureStore(history, undefined, toggleDevTools)

const when = createWhen(store, undefined)
const cookies = new Cookies()

const render = (getRoutes: typeof routes) => {
  ReactDOM.render(
    <App
      store={store}
      routes={getRoutes({ store, when, cookies })}
      history={history}
    />,
    document.getElementById('root')
  )
}

render(routes)
