import React from 'react'

import { createSvgIcon } from './createSvgIcon'

const path = (
  <g>
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      r="45"
      strokeDasharray="220"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
        dur="1s"
        repeatCount="indefinite"
      />
    </circle>
  </g>
)

const Loading = createSvgIcon(path, {
  width: 100,
  height: 100,
  viewBox: '0 0 100 100',
})

export { Loading }
