import classnames from 'classnames'
import type { FC, ReactNode } from 'react'

import styles from './Link.css'
import type { LinkIconPosition } from './types'

type Props = {
  icon?: ReactNode
  iconPosition?: LinkIconPosition
  onlyIcon?: boolean
}

export const LinkContent: FC<Props> = ({
  icon,
  onlyIcon,
  iconPosition,
  children,
}) => {
  return (
    <span className={classnames(styles.content, onlyIcon && styles.onlyIcon)}>
      {icon && (
        <span
          className={classnames(
            styles.icon,
            iconPosition === 'right' && styles.icon_position_right
          )}
        >
          {icon}
        </span>
      )}
      {children && <span className={styles.text}>{children}</span>}
    </span>
  )
}
