import classnames from 'classnames'
import type { ElementType, FC } from 'react'

import styles from './Text.css'
import type { TextTheme } from './types'

type Props = {
  size?: 12 | 14 | 16 | 18 | 20 | 22 | 24 | 28 | 32 | 36 | false
  title?: string
  component?: ElementType
  margin?: boolean
  ellipsis?: boolean
  align?: 'left' | 'center' | 'right' | 'justify'
  theme?: TextTheme
}

export const Text: FC<Props> = ({
  children,
  component: Component = 'div',
  size = false,
  title,
  margin = false,
  ellipsis = false,
  align = 'left',
  theme,
}) => {
  return (
    <Component
      className={classnames(
        styles.root,
        theme ? styles[`theme_${theme}`] : undefined,
        {
          [styles.size_12]: size === 12,
          [styles.size_14]: size === 14,
          [styles.size_16]: size === 16,
          [styles.size_18]: size === 18,
          [styles.size_20]: size === 20,
          [styles.size_22]: size === 22,
          [styles.size_24]: size === 24,
          [styles.size_28]: size === 28,
          [styles.size_32]: size === 32,
          [styles.size_36]: size === 36,
          [styles.with_margin]: margin,
          [styles.mode_ellipsis]: ellipsis,
          [styles.align_center]: align === 'center',
          [styles.align_right]: align === 'right',
          [styles.align_justify]: align === 'justify',
        }
      )}
      title={title}
    >
      {children}
    </Component>
  )
}
