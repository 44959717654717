import classnames from 'classnames'
import type { FC } from 'react'

import styles from './SvgIcon.css'

type Props = {
  width?: number | string
  height?: number | string
  viewBox?: string
  noFill?: boolean
}

export const SvgIcon: FC<Props> = ({
  children,
  width = 16,
  height = 16,
  viewBox = '0 0 16 16',
  noFill,
}) => {
  return (
    <svg
      className={classnames(styles.root, {
        [styles.noFill]: noFill,
      })}
      viewBox={viewBox}
      width={width}
      height={height}
      focusable={false}
      style={{
        width,
        height,
      }}
    >
      {children}
    </svg>
  )
}
