import * as Sentry from '@sentry/browser'
import moment from 'moment'
import { bool, shape, string } from 'prop-types'
import React, { Component, PureComponent } from 'react'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import 'intl'
import 'intl/locale-data/jsonp/en'
import 'intl/locale-data/jsonp/ru'

import { config } from '../../config'
import { authReducer, setTokenValid } from '../../redux/auth/auth'
import { getLocaleMessages, localeReducer } from '../../redux/locale'
import { createApi } from '../../utils/api'
import { once } from '../../utils/once'
import { match } from '../../utils/redux'
import { Auth } from '../auth'
import { VerifyEmail } from '../auth/containers/VerifyEmail'
import { Dashboard } from '../dashboard'
import { Invitation } from '../invitation'

if (config.sentryRelease && config.sentryEnvironment && config.sentryDsn) {
  Sentry.init({
    dsn: config.sentryDsn,
    environment: config.sentryEnvironment,
    release: config.sentryRelease,
  })
}

const App = (context) => {
  const AuthContainer = Auth(context)
  const DashboardContainer = Dashboard(context)

  @connect((state) => ({
    isTokenValid: authReducer.getIsTokenValid(state),
  }))
  class AppComponent extends PureComponent {
    static propTypes = {
      isTokenValid: bool,
    }

    constructor(...args) {
      super(...args)
      context.api.checkToken()
    }

    render() {
      const { isTokenValid } = this.props
      if (isTokenValid === false) {
        return <AuthContainer />
      }

      if (isTokenValid === true) {
        return <DashboardContainer />
      }

      return null
    }
  }

  return AppComponent
}

const getComponent = (context) => {
  const AppContainer = App(context)
  const InvitationContainer = Invitation(context)
  const VerifyEmailContainer = VerifyEmail(context)

  @connect((state) => ({
    localeMessages: localeReducer.getMessages(state),
    locale: localeReducer.getLocale(state),
  }))
  class Root extends Component {
    static propTypes = {
      localeMessages: shape({}),
      locale: string,
      location: shape({}),
    }

    componentDidMount() {
      moment.locale('en')
    }

    render() {
      const { locale, localeMessages, location } = this.props
      return (
        <IntlProvider
          location={location} // pass location to avoid blocked re-render when route changes
          locale={locale}
          messages={localeMessages}
          defaultLocale="en-US"
        >
          <Switch>
            <Route path="/invitation" component={InvitationContainer} />
            <Route path="/verifyEmail" component={VerifyEmailContainer} />
            <Route path="/" component={AppContainer} />
          </Switch>
        </IntlProvider>
      )
    }
  }

  return Root
}

const registerReducers = ({ store }) => {
  store.injectReducer(authReducer.key, authReducer)
  store.injectReducer(localeReducer.key, localeReducer)
}

const registerApiHelper = ({ store }) => {
  const api = createApi((isTokenValid) =>
    store.dispatch(setTokenValid(isTokenValid))
  )

  store.addHelper('api', api)

  return api
}

const registerWhen = once(({ when }) => {
  when(match('*'), () => getLocaleMessages('en-US'))
})

const createRoute = (context) => ({
  path: '/',
  component: getComponent(context),
})

const registerModule = (context) => {
  registerReducers(context)
  registerWhen(context)

  const api = registerApiHelper(context)

  return createRoute({ ...context, api })
}

export { registerModule }
