import { createAction, createReducer } from 'redux-act'

import type { AppStore } from '@/utils/redux/store/types'

const REDUCER_KEY = 'auth'

export type AuthState = {
  isTokenValid: null | boolean
}

const initialState: AuthState = {
  isTokenValid: null,
}

export const setTokenValid = createAction<boolean>(
  `${REDUCER_KEY}/setTokenValid`
)

const authReducer = createReducer({}, initialState)
authReducer.on(setTokenValid, (state, isTokenValid) => ({
  ...state,
  isTokenValid,
}))

const selectors = {
  key: REDUCER_KEY,
  getIsTokenValid: (state: AppStore) => state[REDUCER_KEY].isTokenValid,
}

const reducer = Object.assign(authReducer, selectors)

export { reducer as authReducer }
