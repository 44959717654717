import type { FC, ReactNode } from 'react'

import { emptyFunction } from '@/utils/emptyFunction'

import { createClassName } from './createClassName'
import { LinkContent } from './LinkContent'
import type { LinkIconPosition, LinkTheme } from './types'

type Props = {
  theme?: LinkTheme
  type?: 'button' | 'submit'
  icon?: ReactNode
  iconPosition?: LinkIconPosition
  onlyIcon?: boolean
  disabled?: boolean
  className?: string
  onClick?: () => void
  onFocus?: () => void
  onBlur?: () => void
  testId?: string
}

export const LinkButton: FC<Props> = ({
  children,
  icon,
  onlyIcon,
  iconPosition,
  theme,
  type = 'button',
  disabled = false,
  className,
  onClick = emptyFunction,
  onFocus = emptyFunction,
  onBlur = emptyFunction,
  testId,
}) => {
  const buttonClassName = createClassName({
    className,
    theme,
    disabled,
  })

  return (
    <button
      className={buttonClassName}
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={disabled}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      data-testid={testId}
    >
      <LinkContent icon={icon} onlyIcon={onlyIcon} iconPosition={iconPosition}>
        {children}
      </LinkContent>
    </button>
  )
}
