import React from 'react'

import { Loader } from '@/components/common/components/Loader'
import { LinkButton } from '@/ui-kit/atoms/Link/LinkButton'
import { Text } from '@/ui-kit/atoms/Text'

export const getLazyComponent = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentImport: () => Promise<{ default: React.ComponentType<any> }>
): React.FC => {
  const LazyComponent = React.lazy(componentImport)

  return () => (
    <LazyComponentErrorBoundary>
      <React.Suspense fallback={<Loader />}>
        <LazyComponent />
      </React.Suspense>
    </LazyComponentErrorBoundary>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getLazyModule = <T extends any, P extends any>(
  componentImport: () => Promise<{
    registerModule: (context: T) => React.ComponentType<P>
  }>
): ((context: T) => React.FC) => {
  return (context) =>
    getLazyComponent(() =>
      componentImport().then((res) => ({
        default: res.registerModule(context),
      }))
    )
}

class LazyComponentErrorBoundary extends React.Component {
  state = {
    error: false,
  }

  static getDerivedStateFromError() {
    return { error: true }
  }

  render() {
    return this.state.error ? (
      <Text theme="alert">
        Failed to load component, please{' '}
        <LinkButton onClick={() => window.location.reload()}>
          refresh the page
        </LinkButton>{' '}
        to try again
      </Text>
    ) : (
      this.props.children
    )
  }
}
