const { parse, stringify } = JSON

const isStorageSupported = () => {
  const testKey = 'test'

  try {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const storage = window.localStorage
    storage.setItem(testKey, '1')
    storage.removeItem(testKey)
    return true
  } catch (error) {
    return false
  }
}

const emptyFunc = (): undefined => undefined

export class DummyStorage {
  storage = {
    getItem: emptyFunc,

    setItem: emptyFunc,

    removeItem: emptyFunc,
  }

  getItem = this.storage.getItem

  setItem = this.storage.setItem

  removeItem = this.storage.removeItem

  length = 0
}

export class Storage {
  storage: globalThis.Storage

  constructor(nativeStorage: globalThis.Storage) {
    this.storage = nativeStorage
  }

  getItem = (key: string, defaults?: unknown): unknown => {
    const value = this.storage.getItem(key) as string

    if (!value && defaults) {
      return defaults
    }

    try {
      return parse(value)
    } catch (e) {
      return value
    }
  }

  setItem = (key: string, value: unknown): void => {
    this.storage.setItem(key, stringify(value))
  }

  removeItem = (key: string): void => {
    this.storage.removeItem(key)
  }
}

export const storage = isStorageSupported()
  ? new Storage(localStorage)
  : new DummyStorage()
export const storageSession = isStorageSupported()
  ? new Storage(sessionStorage)
  : new DummyStorage()
