import type { RouterState } from 'connected-react-router'
import {
  connectRouter,
  LOCATION_CHANGE,
  routerMiddleware,
} from 'connected-react-router'
import type { History } from 'history'
import type { Reducer } from 'react'
import type { AnyAction, Dispatch, Store as ReduxStore } from 'redux'
import { applyMiddleware, compose, createStore } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { combineReducers, install as installReduxLoop } from 'redux-loop'

import { reducer as whenReducer } from '../when/reducer'

import type { Store } from './types'

const patchStore = (store: ReduxStore, key: string, data: unknown) => {
  const state = store.getState()
  state[key] = data
}

function enhanceRouterReducerWithPreviousLocation(
  reducer: Reducer<RouterState, AnyAction>
) {
  return (state: RouterState, action: AnyAction) => {
    const previousLocation = state && { ...state.location }
    const nextState = reducer(state, action)

    if (action.type === LOCATION_CHANGE) {
      return {
        ...nextState,
        previousLocation,
      }
    }

    return nextState
  }
}

export const configureStore = (
  history: History<unknown>,
  data: Record<string, unknown> | undefined,
  customCompose?: typeof compose
): Store => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const makeRootReducer = (asyncReducers?: any) =>
    combineReducers({
      router: enhanceRouterReducerWithPreviousLocation(connectRouter(history)),
      form: formReducer,
      when: whenReducer,
      ...(asyncReducers ? { ...asyncReducers } : {}),
    })

  const reduxRouterMiddleware = routerMiddleware(history)

  const middlewares = []
  middlewares.push(reduxRouterMiddleware)

  // helpers middleware
  const helpers: Record<string, unknown> = {}
  const helpersMiddleware = () => (next: Dispatch) => (action: AnyAction) =>
    next({ ...action, helpers })
  const addHelper = (key: string, helper: unknown) => {
    helpers[key] = helper
  }
  middlewares.push(helpersMiddleware)

  const composeEnhancers = customCompose ?? compose

  const finalCreateStore = composeEnhancers(
    installReduxLoop(),
    applyMiddleware(...middlewares)
  )(createStore)

  const asyncReducers: Record<string, Reducer<unknown, AnyAction>> = {}

  const store = Object.assign(finalCreateStore(makeRootReducer()), {
    //
    // async reducers
    //
    injectReducer: (key: string, reducer: Reducer<unknown, AnyAction>) => {
      if (Object.prototype.hasOwnProperty.call(asyncReducers, key)) {
        return
      }

      if (data?.[key]) {
        patchStore(store, key, data[key])
      }

      asyncReducers[key] = reducer
      store.replaceReducer(makeRootReducer(asyncReducers))
    },
    //
    // add helper
    //
    addHelper,
  })

  return store
}
