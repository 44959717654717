// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function once<A extends any[], R, T>(
  func: (this: T, ...arg: A) => R
): (this: T, ...arg: A) => R | undefined {
  let ran = false
  // eslint-disable-next-line @typescript-eslint/init-declarations
  let memo: R

  return function (this: T, ...args: A) {
    if (ran) return memo
    ran = true
    memo = func.apply(this, args)

    return memo
  }
}
