import type { Storage } from '@/utils/storage'
import { storage as tokenStorage } from '@/utils/storage'

import { NoTokenError } from './utils/errors'
import type { req as request } from './utils/req'

export const TOKEN_STORAGE_KEY = 'zaya:token'

class TokenService {
  storage: Storage
  req: typeof request
  refreshTokenPromise: Promise<string> | null

  constructor({
    storage = tokenStorage as Storage,
    req,
  }: {
    storage?: Storage
    req: typeof request
  }) {
    this.storage = storage
    this.req = req

    this.refreshTokenPromise = null
  }

  get token(): string {
    return (this.storage.getItem(TOKEN_STORAGE_KEY) as string) ?? ''
  }

  set token(token: string) {
    this.storage.setItem(TOKEN_STORAGE_KEY, token)
  }

  clearToken(): void {
    this.storage.removeItem(TOKEN_STORAGE_KEY)
  }

  async retrieveToken(): Promise<string> {
    const tokenBeforeRefresh = this.token

    if (!tokenBeforeRefresh) {
      throw new NoTokenError('')
    }

    if (this.refreshTokenPromise) {
      return this.refreshTokenPromise
    }

    this.refreshTokenPromise = this.requestRefreshToken({
      token: tokenBeforeRefresh,
    })

    const updatedToken = await this.refreshTokenPromise

    this.refreshTokenPromise = null

    if (tokenBeforeRefresh !== this.token) {
      return this.retrieveToken()
    }

    this.token = updatedToken

    return updatedToken
  }

  async requestRefreshToken({ token }: { token: string }): Promise<string> {
    const response = await this.req<{ data: { token: string } }>(
      null,
      '/v1/auth/refreshToken',
      {
        method: 'POST',
        body: { token },
      }
    )
    return response.data.token
  }
}

export { TokenService }
