import { createReducer } from 'redux-act'

import type {
  AppStore,
  ReducerWithPayLoadFalse,
} from '@/utils/redux/store/types'

import { REDUCER_KEY } from './constants'
import {
  getLocaleMessages,
  getLocaleMessagesFailure,
  getLocaleMessagesSuccess,
  handleGetLocaleMessages,
  handleGetLocaleMessagesFailure,
  handleGetLocaleMessagesSuccess,
} from './getLocaleMessages'
import type { LocaleState } from './types'

const initialState: LocaleState = {
  messages: {},
  locale: 'en-US',
  isLoading: false,
  isLoaded: false,
}

export { getLocaleMessages }

const localeReducer: ReducerWithPayLoadFalse<LocaleState> = createReducer(
  {},
  initialState
)

localeReducer.on(getLocaleMessages, handleGetLocaleMessages)
localeReducer.on(getLocaleMessagesSuccess, handleGetLocaleMessagesSuccess)
localeReducer.on(getLocaleMessagesFailure, handleGetLocaleMessagesFailure)

localeReducer.options({
  payload: false,
})

const selectors = {
  key: REDUCER_KEY,
  getLocale: (state: AppStore) => state[REDUCER_KEY].locale,
  getMessages: (state: AppStore) => state[REDUCER_KEY].messages,
  getIsLoading: (state: AppStore) => state[REDUCER_KEY].isLoading,
  getIsLoaded: (state: AppStore) => state[REDUCER_KEY].isLoaded,
}

const reducer = Object.assign(localeReducer, selectors)

export { reducer as localeReducer }
