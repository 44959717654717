import type { match as routerMatch } from 'react-router-dom'
import { matchPath } from 'react-router-dom'
import type { AnyAction, Store } from 'redux'

import type { AppStore } from '../store/types'

import { register, reducer as whenReducer } from './reducer'

type Predicate = (newState: unknown, prevState?: unknown) => boolean
type Effect = (state: unknown) => AnyAction

type Listener = {
  key: string
  prevState?: unknown
  dispatched?: boolean
  every?: (() => void) | boolean
  predicate: Predicate
  effect: Effect
}

export type When = (
  predicate: Predicate,
  effect: Effect,
  key: string,
  dispatchOnServer?: boolean
) => void

export const createWhen = (store: Store, ssr = false): When => {
  const dispatches: AnyAction[] = []
  let listeners: Listener[] = []

  const processListener = (newState: unknown, listener: Listener) => {
    const { prevState, predicate, effect } = listener
    listener.prevState = newState
    if (predicate(newState, prevState)) {
      const action = effect(newState)

      if (action) {
        dispatches.push(store.dispatch(action))
      }

      listener.dispatched = true
    }
  }

  store.subscribe(() => {
    const newState = store.getState()

    listeners.forEach((listener) => {
      if (!listener.dispatched || listener.every) {
        processListener(newState, listener)
      }
    })
  })

  const registerListener = (listener: Listener) => {
    const { key, predicate } = listener
    const state = store.getState()
    const keys = whenReducer.getKeys(state)

    if (keys.indexOf(key) === -1) {
      if (key) {
        store.dispatch(register(key))
      }
      processListener(store.getState(), listener)
    } else {
      listener.dispatched = !!predicate(state)
      listener.prevState = state
    }

    listeners.push(listener)
  }

  const whenOnce = (
    predicate: Predicate,
    effect: Effect,
    key: string,
    dispatchOnServer = true
  ) => {
    if (!ssr || dispatchOnServer) {
      registerListener({ predicate, effect, key })
    }
  }

  const whenEvery = (
    predicate: Predicate,
    effect: Effect,
    key: string,
    dispatchOnServer = true
  ) => {
    if (!ssr || dispatchOnServer) {
      registerListener({ predicate, effect, key, every: true })
    }
  }

  const loadOnServer = () => {
    if (dispatches.length === 0) {
      return true
    }

    return dispatches.shift()?.then(loadOnServer)
  }

  const clearListeners = () => {
    listeners = []
  }

  const when = whenOnce

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  when.every = whenEvery
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  when.loadOnServer = loadOnServer
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  when.clear = clearListeners

  return when
}

//
// predicates
//

const getPathname = (location?: { pathname: string; search: string }) =>
  location ? `${location.pathname}${location.search}` : ''

export const match = (path: string | string[]) => (
  state: AppStore,
  prevState: AppStore
): false | null | routerMatch => {
  const pathname = getPathname(state.router.location)

  if (prevState) {
    const prevPathname = prevState && getPathname(prevState.router.location)
    return pathname !== prevPathname ? matchPath(pathname, { path }) : false
  }

  return matchPath(pathname, { path })
}
