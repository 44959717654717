import { createAction, createReducer } from 'redux-act'

import type { AppStore } from '../store/types'

export type WhenState = {
  keys: string[]
}

const REDUCER_KEY = 'when'

const initialState: WhenState = {
  keys: [],
}

export const register = createAction<string>(`${REDUCER_KEY}/register`)

const handleRegister = (state: WhenState, key: string) => ({
  keys: [...state.keys, key],
})

const reducers = createReducer((on) => {
  on(register, handleRegister)
}, initialState)

const selectors = {
  getKeys: (state: AppStore) => state[REDUCER_KEY].keys,
}

const reducer = Object.assign(reducers, selectors)

export { reducer }
