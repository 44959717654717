import classnames from 'classnames'

import styles from './Link.css'
import type { LinkTheme } from './types'

type Props = {
  className?: string
  theme?: LinkTheme
  disabled: boolean
}

export const createClassName = ({
  className,
  theme = 'brand',
  disabled,
}: Props): string =>
  classnames(styles.root, className, styles[`theme_${theme}`], {
    [styles.disabled]: disabled,
  })
