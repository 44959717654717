export class NoTokenError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'NoTokenError'
  }
}

export class ConnectionError extends Error {
  code: string
  constructor() {
    super('Connection error')
    this.name = 'ConnectionError'
    this.code = 'CONNECTION_ERROR'
  }
}

export class ResponseError extends Error {
  status: number
  constructor({ status, message }: { status: number; message: string }) {
    super(message)
    this.status = status
    this.name = 'ResponseError'
  }
}
