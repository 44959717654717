import React from 'react'

import { SvgIcon } from '../SvgIcon'

export type Props = {
  width?: number | string
  height?: number | string
  viewBox?: string
  noFill?: boolean
}

export const createSvgIcon = (
  path: JSX.Element,
  props: Props = {}
): React.NamedExoticComponent<Props> =>
  React.memo<Props>((ownProps) => {
    const {
      width = props.width,
      height = props.height,
      viewBox = props.viewBox,
      noFill = props.noFill,
    } = ownProps

    return (
      <SvgIcon width={width} height={height} viewBox={viewBox} noFill={noFill}>
        {path}
      </SvgIcon>
    )
  })
